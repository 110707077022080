// utils
import scroll from '../utils/scrollLock';

// classes
const headerMenuOpen = 'header-menu--open';
const modalOpenClass = 'modal--open';

// elements
const mainMenuTriggerIcon = [...document.querySelectorAll('.main-menu__icon')];
const headerMenu = document.querySelector('.header-menu');
const modal = document.querySelector('.modal');

let active = false;

const openMainMenu = () => {
    active = true;
    // menu
    headerMenu.classList.add(headerMenuOpen);
    scroll.lock();
};

const closeMainMenu = () => {
    active = false;
    // menu
    headerMenu.classList.remove(headerMenuOpen);

    // don't unlock if modal gets opened
    if (!modal.classList.contains(modalOpenClass)) {
        scroll.unlock();
    }
};

// events
mainMenuTriggerIcon.forEach((icon) => {
    icon.addEventListener('click', () => {
        active ? closeMainMenu() : openMainMenu();
    });
});


window.addEventListener('keyup', ({ key }) => {
    if (active && (key === 'Escape' || key === 'Esc')) {
        closeMainMenu();
    }
});

window.addEventListener('click', ({ target }) => {
    if (active) {
        const isClickInside = header.contains(target);
        const isClickBackdrop = target.classList.contains('modal__backdrop');

        if ((target.getAttribute('href')) || (!isClickInside || isClickBackdrop)) {
            closeMainMenu();
        }
    }
});

window.addEventListener('resize', () => {
    active && closeMainMenu();
});
