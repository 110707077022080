import { isMobile } from '../utils/isMobile';

const $ = jQuery;
const $bodyHtml = $('body, html');
const speed = 800;

const smoothScroll = (target) => {
    if (isMobile()) {
        setTimeout(() => {
            $bodyHtml.animate({
                scrollTop: target ? target.offset().top - 100 : window.innerHeight
            }, speed);
        }, 0);
    } else {
        $bodyHtml.animate({
            scrollTop: target ? target.offset().top - 100 : window.innerHeight
        }, speed);
    }
};

$('a[href*="#"]')
    .not('[href="#"]')
    .not('.modal__trigger')
    .click(function (e) {
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname
        ) {
            e.preventDefault();
            let target = $(this.hash);
            target.length && smoothScroll(target);
        }
    });

// scroll to hash if coming from another page
$(window).on('load', () => {
    if (window.location.hash) {
        const $hash = $(window.location.hash);

        $hash.length > 0 && smoothScroll($hash);
    }
});
