
const scrollTrigger = (selector, options = {}) => {
    let els = document.querySelectorAll(selector)
    els = Array.from(els)
    els.forEach((el) => {
        addObserver(el, options)
    });
};

const addObserver = (el, options) => {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (options.cb) {
                    options.cb(el)
                } else {
                    entry.target.classList.add('scroll-reveal--active')
                }
                observer.unobserve(entry.target)
            };
        });
    }, options);

    // adding the observer to the element
    observer.observe(el)
};

// example usage
scrollTrigger('.scroll-reveal', {
    threshold: 0.5,
});
