const breakpoints = { // Defined by variables.scss
    s: 421,
    m: 701,
    l: 1181,
    xl: 1481,
};

export const isMobile = (width = window.innerWidth) => {
    return width < breakpoints.l;
};
