const header = document.querySelector('.header');

const checkPosition = () => {
    document.documentElement.scrollTop > 50
        ? header.classList.remove('header--on-top')
        : header.classList.add('header--on-top');
};

(() => {
    checkPosition();
})();

window.addEventListener('scroll', checkPosition);
