<template>
    <div class="modules">
        <h2 v-html="sectionTitle"></h2>
        <div class="row">
            <div class="column modules-accordions">
                <div class="accordion">
                    <div v-for="(item, idx) in accordions"
                        :key="`accordion-item-${idx}`"
                        :class="{ 'accordion-item--active': activeId === item.id }"
                        class="accordion-item"
                        :style="{'--color': item.color}">
                        <div class="accordion__header" @click="goToTop(idx + 1, item.id)">
                            <p>{{ item.title }}</p>
                        </div>
                        <transition name="accordion-toggle" mode="out-in" appear>
                            <div class="accordion__content" v-if="item.id === activeId">
                                <div v-html="item.text"
                                    class="accordion__content-wrapper text-formatted">
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="column modules-hexagons">
                <div>
                    <div class="hexagons menu" :style="{'--menu-rotation': `${rotation}deg`}">
                        <div v-for="(hexagon, idx) in hexagons"
                            :key="hexagon.id"
                            class="hexagon"   
                            :class="{ 'hexagon--active': activeId === hexagon.id }"
                            :style="{
                                '--color': hexagon.color,
                                '--axis-rotation': `${360 * (idx) / buttons}deg`
                            }">
                            <div @click="goToTop(idx + 1, hexagon.id)">
                                <div class="hex hex-outline"></div>
                                <div class="hex hex-border"></div>
                                <div class="hex hex-inner"></div>
                                <div class="hexagon__content">
                                    <responsive-image :images="hexagon.images"></responsive-image>
                                    <p>{{ hexagon.title.toLowerCase() }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // components
    import ResponsiveImage from './ResponsiveImage';

    export default {
        name: 'modules',
        components: {
            ResponsiveImage,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            modules: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                accordions: [],
                hexagons: [],
                activeId: 0,
                buttons: this.modules.length,
                rotation: -180
            };
        },
        computed: {
            sectionTitle() {
                return `<span>${this.modules.length}</span> ${this.title}`;
            },
            axisRotations() {
                return Array.from({
                    length: this.buttons
                }).map((_, i) => (360 * (this.buttons - i)) / this.buttons);
            },
            menuRotation: {
                get() {
                    return this.rotation;
                },
                set(val) {
                    this.rotation = isNaN(Number(val)) ? -180 : Number(val);
                }
            },
        },
        mounted() {
            // separate content into sections
            this.modules.forEach((module) => {
                const { id, title, text, color, images } = module;

                this.accordions.push({ id, title, text, color });
                this.hexagons.push({ id, title, images, color });
            });

            // set the first element as active
            this.activeId = this.accordions.length && this.accordions[0].id;
        },
        methods: {
            setActiveElement(id, { target }) {
                this.activeId = id;
            },
            goToTop(axis, id) {
                let diff = this.degreesToTop(axis);
                diff = diff > 180 ? diff - 360 : diff <= -180 ? diff + 360 : diff;
                this.menuRotation =
                    Math.round((this.menuRotation + diff) * 10) / 10;
                this.activeId = id;
            },
            degreesToTop(axis) {
                return (
                    (Math.round(this.axisRotations[axis - 1]) -
                        this.menuRotation -
                        180) %
                    360
                );
            },
        },
    };
</script>
