// utils
import scroll from './scrollLock';

// classes
const headerMenuOpen = 'header-menu--open';
const modalOpenClass = 'modal--open';

// elements
const headerMenu = document.querySelector('.header-menu');

export const openModal = (modal) => {
    scroll.lock();
    modal.classList.add(modalOpenClass);

    // close main menu if opened
    if (headerMenu.classList.contains(headerMenuOpen)) {
        headerMenu.classList.remove(headerMenuOpen);
    }
};

export const closeModal = (modal) => {
    scroll.unlock();
    modal.classList.remove(modalOpenClass);
};

export const allowClosingWithEsc = (modal) => {
    window.addEventListener('keyup', ({ key }) => {
        if (key === 'Escape' || key === 'Esc') {
            const isOpen = modal.classList.contains(modalOpenClass);
            if (isOpen) {
                closeModal(modal);
            }
        }
    });
};
