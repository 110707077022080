import Vue from 'vue';

// polyfills
import TrioAssets from 'trio-assets-js';
(() => {
    TrioAssets.parallax();
})();

// stylings
import './scss/app.scss'; // whole site stylings

// scripts
// utils
import './js/utils/scrollReveal';
import './js/utils/smoothScroll';
// elements
import './js/elements/contactModal';
import './js/elements/header';
import './js/elements/features';
import './js/elements/mainMenu';

Vue.config.productionTip = false;

// components
import Modules from './js/components/Modules';

// register components
Vue.component('modules', Modules);

// vue instances
if (document.getElementById('modules-block')) {
    new Vue({
        el: '#modules-block',
        comments: true,
    });
}
