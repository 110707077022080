// utils
import { isMobile } from '../utils/isMobile';

const features = document.querySelector('.fp__features');
const items = features && [...features.querySelectorAll('.field__item-wrapper')];

const viewport = window.innerHeight;
const offsetTop = isMobile() ? 200 : 0;
const offsetBottom = isMobile() ? 1100 : 500;
let scrollPosition = 0;
let lastTime = 0;

const activeClass = 'field__item--active';

const throttle = () => {
    const now = Date.now();
    if (now - lastTime >= 150) {
        scrollAnimate();
        lastTime = now;
    }
};

const scrollAnimate = () => {
    scrollPosition = window.scrollY;
    // get the bottom third part of the screen
    const middle = scrollPosition + (2 * (viewport / 3));

    const firstBottom = Math.round(items[0].getBoundingClientRect().bottom);
    const lastBottom = Math.round(items[items.length - 1].getBoundingClientRect().bottom);

    const isAboveMiddle = scrollPosition < (firstBottom + offsetTop);
    const isBelowMiddle = scrollPosition > (lastBottom + offsetBottom);

    items.forEach((el) => {
        const { top, bottom } = el.getBoundingClientRect();
        const elTop = Math.round(top + scrollPosition);
        const elBottom = Math.round(bottom + scrollPosition);
        // check the elements position
        const isIntersecting = middle > elTop && middle < elBottom;
        const fieldItem = el.querySelector('.field__item');

        if (isIntersecting) {
            fieldItem.classList.add(activeClass);
        }
        else {
            fieldItem.classList.remove(activeClass);

            if (isAboveMiddle) {
                items[0].querySelector('.field__item').classList.add(activeClass);
            }

            if (isBelowMiddle) {
                items[items.length - 1].querySelector('.field__item').classList.add(activeClass);
            }
        }
    });
};

if (features) {
    window.addEventListener('scroll', throttle);
}
