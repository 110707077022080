<template>
    <picture class="responsive-image">
        <template v-for="img in images" >
            <source :media="img.media" :srcset="img.src" :key="img.media" v-if="img.media"/>
            <img v-if="img.alt"
                :src="img.src"
                :alt="img.alt"
                :key="img.src"
                class="responsive-image__img"
                loading="lazy"
            />
        </template>
    </picture>
</template>

<script>
    export default {
        name: 'responsive-image',
        props: {
            images: {
                type: [Array, Object],
                required: true
            }
        }
    };
</script>
