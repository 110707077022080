// utils
import { openModal, closeModal, allowClosingWithEsc } from '../utils/modal';

const modalTriggers = [...document.querySelectorAll('.modal__trigger')];
const modal = document.querySelector('#modal-contact');
const modalTabs = document.querySelectorAll('.modal-tabs__tab');
const modalContent = document.querySelectorAll('.modal-tabs__content-item');

const tabActive = 'modal-tabs__tab--active';
const tabContentActive = 'modal-tabs__content-item--active';

const init = () => {
    const close = modal.querySelector('.modal__close');
    const overlay = modal.querySelector('.modal__backdrop');

    [...modalTriggers, close, overlay].forEach((selector) => {
        selector.addEventListener('click', (event) => {
            event.preventDefault();
            const activeLink = event.target.getAttribute('href')?.replace('#', '');
            const isOpen = modal.classList.contains('modal--open');
            if (isOpen) {
                closeModal(modal);
                removeActive([...modalTabs, ...modalContent]);
            } else {
                openModal(modal);
                setActive(activeLink);
            }

            toggleType(activeLink);
        });
    });

    // add Esc key listener
    allowClosingWithEsc(modal);
};

const removeActive = (items) => {
    items.forEach((item) => {
        item.classList.remove(tabActive, tabContentActive);
    });
};

const setActive = (modalActive) => {
    document.querySelector(`#tab-${modalActive}`).classList.add(tabActive);
    document.querySelector(`#content-${modalActive}`).classList.add(tabContentActive);
};

const toggleType = (modalActive) => {
    if (modal.classList.contains('modal--demo')) {
        modal.classList.remove('modal--demo');
    }

    if (modal.classList.contains('modal--advice')) {
        modal.classList.remove('modal--advice');
    }

    if (modalActive) {
        modal.classList.add(`modal--${modalActive}`);
    }
};

const toggleTabs = () => {
    modalTabs.forEach((tab) => {
        tab.addEventListener('click', ({ target }) => {
            const activeId = target.id.split('-')[1];
            const activeContent = document.querySelector(`#content-${activeId}`);

            removeActive([...modalTabs, ...modalContent]);

            // add active classes
            tab.classList.add(tabActive);
            activeContent.classList.add(tabContentActive);

            toggleType(activeId);
        });
    });
}

(() => {
    if (modalTriggers.length) {
        init();
        toggleTabs();
    }
})();
